import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import { useNavigate } from '@reach/router'
import BlockContent from '../components/BlockContent'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import ExhibitorsGrid from '../components/ExhibitorsGrid'

export default props => {
  const { data } = props
  const { edges: companies } = data.allSanityExhibitors
  const { sanityVendorType: challengeType } = data

  const [defaultOrder, setDefaultOrder] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState('')
  const [selectedChallengeType, setSelectedChallengeType] = React.useState(
    challengeType.title
  )
  const [defaultSort, setDefaultSort] = React.useState('asc')

  const navigate = useNavigate()

  React.useEffect(() => {
    initialCompaniesOrder(companies)
    handleCategoryChange(selectedCategory)
  }, [])

  function onAutocompleteChange(event, value) {
    navigate(`/exhibitor/${value.node.slug.current}`)
  }

  function initialCompaniesOrder(comps) {
    R.compose(
      e => setDefaultOrder(e),
      R.sort(R.ascend(R.path(['node', 'title'])))
    )(comps)
  }

  function handleCategorySort(sort) {
    if (R.equals(sort, 'asc')) {
      R.compose(
        () => setDefaultSort(sort),
        e => setDefaultOrder(e),
        R.sort(R.ascend(R.path(['node', 'title'])))
      )(defaultOrder)
    } else {
      R.compose(
        () => setDefaultSort(sort),
        e => setDefaultOrder(e),
        R.sort(R.descend(R.path(['node', 'title'])))
      )(defaultOrder)
    }
  }

  function handleChallengeTypeChange(v) {
    const newChallengeArray = []
    setDefaultSort(defaultSort)
    setSelectedChallengeType(v)

    notNilOrEmpty(v)
      ? R.compose(
          () => initialCompaniesOrder(newChallengeArray),
          mapIndexed(({ node: item }, index) => {
            let eq = R.equals(v, item.challengeType.title)
            if (eq) {
              newChallengeArray.push(companies[index])
            }
          })
        )(companies)
      : initialCompaniesOrder(companies)
  }

  function handleCategoryChange(catName) {
    const newCatsArray = []
    setDefaultSort(defaultSort)
    setSelectedCategory(catName)

    notNilOrEmpty(catName)
      ? R.compose(
          () => initialCompaniesOrder(newCatsArray),
          mapIndexed(({ node: item }, index) => {
            let eq = R.equals(catName, item.categories.title)
            if (eq) {
              newCatsArray.push(companies[index])
            }
          })
        )(companies)
      : initialCompaniesOrder(companies)
  }

  return (
    <Layout>
      <SEO title={`${challengeType.title} | Afwerx Space Challenge`} />
      <Hero
        img={challengeType.challenge_hero_background.asset.fluid.src}
        overlayImg={challengeType.challenge_hero_overlay_image.asset.fluid.src}
        className="challenge-type"
      />
      <div className="container">
        {notNilOrEmpty(challengeType._rawChallengePageBlurb) && (
          <section className="intro-text inner">
            <BlockContent blocks={challengeType._rawChallengePageBlurb} />
          </section>
        )}
        <ExhibitorsGrid
          challengeType={challengeType}
          defaultOrder={defaultOrder}
          defaultSort={defaultSort}
          handleCategoryChange={handleCategoryChange}
          handleCategorySort={handleCategorySort}
          handleChallengeTypeChange={handleChallengeTypeChange}
          onAutocompleteChange={onAutocompleteChange}
          selectedCategory={selectedCategory}
          selectedChallengeType={selectedChallengeType}
        />
      </div>
    </Layout>
  )
}

export const catQuery = graphql`
  query VendorTemplateQuery($_id: String!) {
    sanityVendorType(_id: { eq: $_id }) {
      _rawChallengePageBlurb
      title
      categories {
        slug {
          current
        }
        title
      }
      challenge_hero_overlay_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      challenge_hero_background {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityExhibitors(filter: { challengeType: { _id: { eq: $_id } } }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          company_img {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          categories {
            slug {
              current
            }
            title
          }
        }
      }
    }
  }
`
